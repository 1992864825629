import { Layout } from '@components/common/Layout';
import { Heading } from '@components/ui/Heading';
import { useAuthUser } from '@lib/authentication';
import { useRouter } from 'next/router';
import React from 'react';
import { useEffect } from 'react';
import { AuthUI } from './AuthUI';

export function AuthLayout({ type }: { type: 'login' | 'signup' }) {
  const title = type === 'login' ? 'Sign in' : 'Sign up';
  const router = useRouter();
  const { authenticated } = useAuthUser();
  const returnTo = router.query.returnTo?.toString();
  useEffect(() => {
    if (authenticated) {
      if (returnTo && returnTo.startsWith('/')) {
        router.replace(returnTo);
      } else {
        router.replace('/');
      }
    }
  }, [authenticated, returnTo]);
  return (
    <Layout
      title={title}
      className="w-full max-w-md px-4 py-6 mx-auto"
      data-testid="AuthLayout"
    >
      <Heading level="h1" size="lg" className="mb-4 text-center">
        {title}
      </Heading>
      <AuthUI type={type} />
    </Layout>
  );
}
